<template>
    <k-layout>
        <div class="questionaire-page">
            <div class="tw-bg-gray-900 tw-w-full tw-py-24 tw-text-gray-50 tw-text-center">
                <h1 class="tw-heading-1">{{ $t("questionaire.title") }}</h1>
                <p class="tw-heading-4 tw-mt-4">
                    {{ selectedProfession && selectedProfession.name }}
                </p>
            </div>
            <div class="questionaire-container">
                <div class="questionaire-select-method kod-shadow">
                    <h3 class="tw-heading-3">
                        {{ $t("questionaire.subtitles.choose_method") }}
                    </h3>
                    <hr />
                    <div v-if="selectedProfession && 'form' in selectedProfession" class="selecting-methods">
                        <div class="methods">
                            <div id="ga_questionaire_method-cv" @click="resolveMethod('cv')">
                                <img src="/web/questionaire/cv.svg" alt="form" />
                                <h4 class="tw-heading-5">{{ $t("questionaire.actions.upload_resume") }}</h4>
                                <p class="body-text">{{ $t("questionaire.descriptions.experience_evaluate_by_team") }}</p>
                            </div>
                        </div>
                        <div class="methods">
                            <div id="ga_questionaire_method-form" @click="resolveMethod('form')">
                                <img src="/web/questionaire/form.svg" alt="form" />
                                <h4 class="tw-heading-5">{{ $t("global.titles.fill_out_form") }}</h4>
                                <p class="body-text">{{ $t("questionaire.descriptions.experience_evaluate_by_system") }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="selecting-methods">
                        <div class="method">
                            <div id="ga_questionaire_method-cv" @click="resolveMethod('cv')">
                                <img src="/web/questionaire/cv.svg" alt="cv" />
                                <h4>{{ $t("questionaire.actions.upload_resume") }}</h4>
                                <p class="body-text">{{ $t("questionaire.descriptions.experience_evaluate_by_pro_team") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </k-layout>
</template>

<script>
import { DEFAULT_LOCALE, getRouteLocale } from "@web/constants/language";
import { decodeRouteParams, encodeRouteParam } from "@web/utils/router";

export default {
    name: "QuestionairePage",
    computed: {
        pathPrefix() {
            const locale = this.$store.getters.appLocale;

            return locale === DEFAULT_LOCALE ? "" : `/${locale}`;
        },
        locale() {
            return getRouteLocale();
        },
        professions() {
            const professions = this.$store.getters["ENUMS/allStacksNames"];

            return professions.filter(item => item.value !== "Iné");
        },
        selectedProfession() {
            if (!this.professions.length) {
                return {};
            }

            const { queryProfession } = this.$route.params;

            if (!queryProfession) {
                return null;
            }

            const selectedProfession = this.professions.find(item => item.name.toLowerCase() === decodeRouteParams(queryProfession));

            if (!selectedProfession) {
                return null;
            }

            return selectedProfession;
        }
    },
    methods: {
        resolveMethod(method) {
            let routerObject = {};

            if (method === "form") {
                switch (this.selectedProfession.form) {
                    case "programmer":
                        routerObject.name = "ProgrammerFormQuestionaire";
                        routerObject.params = {
                            locale: this.locale,
                            programmer: encodeRouteParam(this.selectedProfession.name)
                        };

                        break;
                    case "tester":
                        routerObject.name = "TesterFormQuestionaire";
                        routerObject.params = {
                            locale: this.locale
                        };

                        break;
                    default:
                        this.redirect();
                }
            } else {
                routerObject.name = "CVQuestionaire";
                routerObject.params = {
                    locale: this.locale,
                    queryProfession: encodeRouteParam(this.selectedProfession.name)
                };
            }

            this.$router.push(routerObject);
        },
        redirect() {
            this.$router.push({ path: this.pathPrefix + "/adekvatny-plat" });
        }
    },
    watch: {
        selectedProfession(newValue) {
            if (newValue === null) {
                this.redirect();
            }
        }
    }
};
</script>
<style lang="scss">
@import "@web/sass/variables.scss";

.questionaire-container {
    @media (max-width: 63.938rem) {
        max-width: 41rem;
        padding: 0 1rem;
    }
}

.questionaire-select-method {
    @include margin-top-multiplier(5);
    @include padding-top-multiplier(7);
    @include padding-bottom-multiplier(7);
    @include padding-left-multiplier(8);
    @include padding-right-multiplier(8);

    width: 100%;
    max-width: 51.5rem;
    background: white;

    @media (max-width: 63.938rem) {
        @include padding-top-multiplier(3);
        @include padding-bottom-multiplier(3);
        @include padding-left-multiplier(2);
        @include padding-right-multiplier(2);
    }

    hr {
        @include margin-top-multiplier(1);
        border: none;
        border-top: solid 0.063rem $koderia-border-grey;
    }
    .selecting-methods {
        @include margin-top-multiplier(4);

        display: flex;
        flex-direction: row;

        @media (max-width: 63.938rem) {
            flex-direction: column;
        }

        .method > div,
        .methods > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media (max-width: 63.938rem) {
                align-items: center;
                text-align: center;
            }
            @include padding-top-multiplier(4);
            @include padding-bottom-multiplier(4);
            @include padding-left-multiplier(5);
            @include padding-right-multiplier(5);

            border: solid 0.063rem $koderia-whitesmoke;
            border-radius: 0.125rem;

            img,
            h4 {
                @include margin-bottom-multiplier(2);
            }

            &:hover {
                cursor: pointer;
                box-shadow: 0 0.063rem 0.25rem $koderia-shadow;
            }
        }

        .method {
            width: 100%;
        }

        .methods {
            &:first-child {
                @include padding-right-multiplier(2);
            }
            &:last-child {
                @include padding-left-multiplier(2);
            }

            width: 50%;

            @media (max-width: 63.938rem) {
                &:first-child {
                    @include padding-right-multiplier(0);
                }
                &:last-child {
                    @include padding-top-multiplier(2);
                    @include padding-left-multiplier(0);
                }
                width: 100%;
            }
        }
    }
}
</style>
